import * as React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import Link from '@material-ui/core/Link'
import ReactImage from 'react-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import contentfulOptions from '../common/contentful-rich-text-options'

const { richTextFromMarkdown } = require('@contentful/rich-text-from-markdown')

import { Layout } from '../components/layout'
import SEO from '../components/seo'

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

export default ({ pageContext }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p>
          <Typography variant="body1">{children}</Typography>
        </p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <p>
          <Typography variant="h1" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <p>
          <Typography variant="h2" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <p>
          <Typography variant="h3" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <p>
          <Typography variant="h4" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <p>
          <Typography variant="h5" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <p>
          <Typography variant="h6" gutterBottom={true}>
            {children}
          </Typography>
        </p>
      )
    }
  }
  return (
    <Layout>
      <SEO title="Dental Services" />
      <Container maxWidth="md">
        <Box my={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en">
              Home
            </Link>
            <Typography color="textPrimary">News</Typography>
          </Breadcrumbs>
        </Box>
        <ReactImage
          src={pageContext.imageSrc}
          style={{ width: '100%', borderRadius: 5 }}
        />
        <Box my={3}>
          <Typography variant="h4">{pageContext.title}</Typography>
        </Box>
        {documentToReactComponents(pageContext.body, options)}
      </Container>
    </Layout>
  )
}
